import Flatpickr from 'stimulus-flatpickr'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'
import { differenceInCalendarDays } from 'date-fns'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Dutch
    }
  }

  change(selectedDates, dateStr, instance) {
    document.getElementById("order_shipments_attributes_0_delivery_moment_0").disabled = true;
    document.getElementById("order_shipments_attributes_0_delivery_moment_0").checked = false;
    document.getElementById("order_shipments_attributes_0_delivery_moment_1").checked = true;

    var today = new Date()
    var selectedDate = selectedDates[0]
    var days_diff = differenceInCalendarDays(selectedDate, today);

    if(days_diff == 0) {
      document.getElementById("order_shipments_attributes_0_delivery_moment_0").disabled = true;
      document.getElementById("order_shipments_attributes_0_delivery_moment_0").checked = false;
      document.getElementById("order_shipments_attributes_0_delivery_moment_1").checked = true;
    }else{
      if(today.getHours() < 14) {
        document.getElementById("order_shipments_attributes_0_delivery_moment_0").disabled = false;
      }else{
        if(days_diff > 1) {
          document.getElementById("order_shipments_attributes_0_delivery_moment_0").disabled = false;
        }
      }
    }

    // Saturday pickup only available until 12.
    if(selectedDate.getDay() == 6) {
      document.getElementById("order_shipments_attributes_0_delivery_moment_0").disabled = false;
      document.getElementById("order_shipments_attributes_0_delivery_moment_0").checked = true;

      document.getElementById("order_shipments_attributes_0_delivery_moment_1").disabled = true
      document.getElementById("order_shipments_attributes_0_delivery_moment_1").checked = false
    }
  }
}
