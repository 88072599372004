// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const importAll = (r) => r.keys().map(r);
importAll(require.context("../images", false, /\.(png|jpe?g|svg)$/));

import $ from "jquery";
global.$ = $;
global.jQuery = $;

require("@rails/ujs").start();
require("turbolinks").start();

// jquery-ui theme
// require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true,    /jquery-ui\.css/ );
// require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true,    /jquery-ui\.theme\.css/ );

// import Swiper from "swiper";
// import { Navigation } from "swiper/modules";

// import "swiper/css";
// import "swiper/css/navigation";
import "slick-carousel";
import "bootstrap";
import "../stylesheets/application";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

// Stimulus controllers
import "controllers";

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $.rails.refreshCSRFTokens();

  $(".mobile-menu-open-7").on("click", function () {
    $(".navbar-sidebar-7").addClass("open");
    $(".mobile-menu-open-7").addClass("hide");
    $(".mobile-menu-close-7").removeClass("hide");
  });

  $(".mobile-menu-close-7").on("click", function () {
    $(".navbar-sidebar-7").removeClass("open");
    $(".mobile-menu-open-7").removeClass("hide");
    $(".mobile-menu-close-7").addClass("hide");
  });

  function displayWindowSize() {
    var w = document.documentElement.clientWidth;
    if (w < 768) {
      $(".main-navbar").addClass("mobile-menu");

      /*-------- Mobile Menu javascript Function --------*/

      /*Variables*/
      var $offCanvasNav = $(".mobile-menu");
      var $offCanvasNavSubMenu = $offCanvasNav.find(
        ".sub-mega-dropdown, .sub-menu, .mega-dropdown-list ul"
      );

      /*Close Off Canvas Sub Menu*/
      $offCanvasNavSubMenu.slideUp();

      /*Category Sub Menu Toggle*/

      $offCanvasNav.off();
      $offCanvasNav.on("click", "ul li .menu-expand", function (e) {
        console.log("menu can expand");
        var $this = $(this);
        if ($this.parent().attr("class").match("menu-item-has-children")) {
          if ($this.siblings("ul:visible").length) {
            e.preventDefault();
            $this.parent("li").removeClass("active");
            $this.siblings("ul").slideUp();
          } else {
            $this.parent("li").addClass("active");
            $this.closest("li").siblings("li").find("ul:visible").slideUp();
            $this.closest("li").siblings("li").removeClass("active");
            $this.siblings("ul").slideDown();
          }
        }
      });
    }
  }
  window.addEventListener("resize", displayWindowSize);
  displayWindowSize();

  $(".add").on("click", function () {
    if ($(this).prev().val()) {
      $(this)
        .prev()
        .val(+$(this).prev().val() + 1);
    }
  });

  $(".sub").on("click", function () {
    if ($(this).next().val() > 1) {
      if ($(this).next().val() > 1)
        $(this)
          .next()
          .val(+$(this).next().val() - 1);
    }
  });

  $(".size-select li").on("click", function () {
    $(this).siblings(this).removeClass("active").end().addClass("active");
  });

  if ($("#checkout_form_address").is("*")) {
    var order_use_billing = $("input#order_use_billing");
    order_use_billing.on("change", function () {
      update_shipping_form_state(order_use_billing);
    });

    var update_shipping_form_state = function (order_use_billing) {
      if (order_use_billing.is(":checked")) {
        $('section[data-hook="shipping_inner"]').hide();
        $(
          'section[data-hook="shipping_inner"] input, section[data-hook="shipping_inner"] select'
        ).prop("disabled", true);
      } else {
        $('section[data-hook="shipping_inner"]').show();
        $(
          'section[data-hook="shipping_inner"] input, section[data-hook="shipping_inner"] select'
        ).prop("disabled", false);
      }
    };
    update_shipping_form_state(order_use_billing);
  }

  if ($("form#update-cart").is("*")) {
    $("form#update-cart a.delete")
      .show()
      .one("click", function () {
        $(this)
          .parents(".line-item")
          .first()
          .find("input.line_item_quantity")
          .val(0);
        $(this).parents("form").first().submit();
        return false;
      });
  }

  var radios = $('#product-variants input[type="radio"]');
  if (radios.length > 0) {
    var selectedRadio = $(
      '#product-variants input[type="radio"][checked="checked"]'
    );
    // Spree.showVariantImages(selectedRadio.attr("value"));
    Spree.updateVariantPrice(selectedRadio);
  }

  // Spree.addImageHandlers();
  radios.on("click", function (event) {
    Spree.showVariantImages(this.value);
    Spree.updateVariantPrice($(this));
  });

  $(".search-btn-desktop").on("click", function (event) {
    $(".navbar-search-desktop").toggleClass("navbar-search-desktop--open");
    $(".navbar-search-desktop-search").toggleClass("lni lni-search-alt");
    $(".navbar-search-desktop-search").toggleClass("mdi mdi-close");
  });

  $(".search-btn-mobile").on("click", function (event) {
    $(".search-mobile").toggleClass("search-mobile--open");
    $(".search-btn-mobile--search-icon").toggleClass("hide");
    $(".search-btn-mobile--close-icon").toggleClass("hide");
  });

  $(".filter-mobile-button").on("click", function (event) {
    $(".filter-wrapper-mobile__container").toggleClass("hide-filter");
    $(".filter-wrapper-mobile__scroll-container").toggleClass(
      "hide-filter-inner"
    );
    $("body").toggleClass("overflow-y-0");
  });

  $(".filter-button-close").on("click", function (event) {
    $(".filter-wrapper-mobile__container").toggleClass("hide-filter");
    $(".filter-wrapper-mobile__scroll-container").toggleClass(
      "hide-filter-inner"
    );
    $("body").toggleClass("overflow-y-0");
  });

  $(".filter_item").on("click", function (event) {
    $("#sidebar_products_search").trigger("submit");
  });

  $(".pop-up__container-click").on("click", function (event) {
    $(".pop-up__container").addClass("hide");
  });

  $(".product-btn").on("click", function (event) {
    $(
      ".pop-up__container[data-product=" +
        $(this).attr("data-target-product") +
        "]"
    ).toggleClass("hide");
  });

  $(".pop-up-homepage__container-click").on("click", function (event) {
    $(".pop-up-homepage__container-click").addClass(
      "hide-pop-up-homepage__container-click"
    );
    $(".pop-up-homepage").addClass("hide-pop-up-homepage");
  });

  $(".pop-up-homepage-close-btn").on("click", function (event) {
    $(".pop-up-homepage__container-click").addClass(
      "hide-pop-up-homepage__container-click"
    );
    $(".pop-up-homepage").addClass("hide-pop-up-homepage");
  });

  $(".open-coupon-btn").on("click", function (event) {
    $(".modal-checkout-coupon").toggleClass("hide");
  });

  $(".close-modal-coupon").on("click", function (event) {
    $(".modal-checkout-coupon").toggleClass("hide");
  });

  $(".navbar-top").slick({
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  function init() {
    const swiper = new Swiper(".delivery-swiper", {
      slidesPerView: 1,
      spaceBetween: 12,
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      breakpoints: {
        400: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      },
    });
  }

  init();
});

Spree.disableSaveOnClick = function () {
  $("form.edit_order").submit(function () {
    $(this)
      .find(":submit, :image")
      .attr("disabled", true)
      .removeClass("primary")
      .addClass("disabled");
  });
};

Spree.ready(function ($) {
  var termsCheckbox = $("#accept_terms_and_conditions");
  termsCheckbox.change(function () {
    var submitBtn = $(this.closest("form")).find(":submit");
    submitBtn.prop("disabled", !this.checked);
    submitBtn.toggleClass("disabled", !this.checked);
  });
});
