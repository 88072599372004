import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ["checked"]

  selectDelivery(event){
    const elements = document.getElementsByClassName("fake-radio");
    for (let elem of elements) {
      elem.classList.remove("checked");
    }

    document.getElementById("order_shipments_attributes_0_delivery_moment").value = this.data.get("delivery-moment");
    document.getElementById("order_shipments_attributes_0_delivery_date").value = this.data.get("delivery-date");
    document.getElementById("order_shipments_attributes_0_trunkrs_timeslot_id").value = this.data.get("trunkrs-timeslot-id");
    this.element.classList.toggle(this.checkedClass);
  }
}
