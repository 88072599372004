import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["passwordInputs", "addressInputs", "addressDisplay", "zipcodeField", "houseNumberField"]
  previousZipcode = '';
  previousHousenumber = '';
  numberTimeout = '';

  connect() {
    if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
      document.querySelector('[value="applepay"]').parentElement.removeAttribute('hidden');
    }
  }

  togglePasswordInputs(event){
    this.passwordInputsTarget.classList.toggle("hide");
  }

  toggleAddressInputs(event){
    event.preventDefault();
    this.addressInputsTarget.classList.toggle("hide");
    this.addressDisplayTarget.classList.toggle("hide")
  }

  addressInputChange() {
    const zipcode = (this.zipcodeFieldTarget.value || '').replace(/\s+/g, '');
    const houseNumber = (this.houseNumberFieldTarget.value || '').replace(/\s+/g, '');

    if (this.previousZipcode !== zipcode || this.previousHousenumber !== houseNumber) {
      this.setCityAndStreetValue('', '');
      this.zipcodeFieldTarget.classList.remove('form-error');
      this.houseNumberFieldTarget.classList.remove('form-error');

      if (zipcode.length === 6 && houseNumber.length > 0) {
        this.previousZipcode = zipcode;
        this.previousHousenumber = houseNumber;
        const data = { zipcode, house_number: houseNumber };
        if (this.keyupTimeout) {
          clearTimeout(this.keyupTimeout);
        }
        this.keyupTimeout = setTimeout(() => { this.zipcodeRequest(data) }, 300);
      }
    }
  }

  shippingMethodChanged(event){
    Rails.ajax(
      {
        url: "/checkout/update/delivery?shipment_method_changed=true",
        type: "PATCH",
        data: new FormData(document.getElementById("checkout_form_delivery")),
        success: (data) => {},
        error: () => {
          alert("Something wend wrong, please try again");
        }
      }
    )
  }

  zipcodeRequest(data) {
    Rails.ajax(
      {
        url: "/api/address/autocomplete",
        type: "POST",
        data: new URLSearchParams(data).toString(),
        success: (data) => {
          this.setCityAndStreetValue(data.city, data.street);
        },
        error: () => {
          this.zipcodeFieldTarget.classList.add('form-error');
          this.houseNumberFieldTarget.classList.add('form-error');
        }
      }
    );
  }

  setCityAndStreetValue(city, street) {
    document.getElementById('order_bill_address_attributes_city').value = city;
    document.getElementById('order_bill_address_attributes_address1').value = street;
  }
}
