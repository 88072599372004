import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["variantPrice", "overlay", "addedToCartPopup"];

  set_price(event) {
    this.variantPriceTarget.innerHTML = event.currentTarget.dataset["price"];
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    document.getElementById("link-to-cart").innerHTML = xhr.response;
    document.getElementById("link-to-cart-mobile").innerHTML = xhr.response;
    this.overlayTarget.classList.add("hide");
    console.log(this.overlayTarget);
    this.addedToCartPopupTarget.classList.remove("hide");

    setInterval(() => {
      this.addedToCartPopupTarget.classList.add("hide");
    }, 4000);
  }

  add_to_cart(event) {
    Rails.fire(this.element, "submit");
  }

  closeAddToCartPopup(event) {
    this.addedToCartPopupTarget.classList.add("hide");
  }
}
