/**
 * Cookie Manager.
 */
export default class Cookie {
  /**
   * Sets a cookie.
   *
   * @param  {String} cookieName
   * @param  {String} cvalue
   * @param  {Number} exdays
   */
  static set(cookieName, cvalue, exdays = 365) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    document.cookie = `${cookieName}=${cvalue};expires=${d.toUTCString()};path=/`;
  }

  /**
   * Gets a cookie.
   *
   * @param  {String} cookieName
   *
   * @return {String|Boolean}
   */
  static get(cookieName) {
    const name = `${cookieName}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
      const c = ca[i].trim();

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return false;
  }

  /**
   * Deletes a cookie.
   *
   * @param  {String} cookieName
   */
  static delete(cookieName) {
    if (Cookie.get(cookieName)) {
      Cookie.set(cookieName, '', -1);
    }
  }
}
