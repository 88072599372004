/* eslint-disable no-param-reassign */

import { Controller } from 'stimulus';
import Cookie from '../cookie';

export default class extends Controller {
  static targets = ['form', 'option', 'embed', 'infoBubble', 'cookieSettings'];

  /**
   * Cookie storage key
   * @type {String}
   */
  storageKey = 'cookiePreferences';

  /**
   * Add event listeners for the form
   */
  connect() {
    const cookie = Cookie.get(this.storageKey);

    this.initBanner(cookie);
    this.initEmbeds(cookie);
  }

  /**
   * Initialize the banner.
   *
   * @param  {String} cookie
   */
  initBanner(cookie) {
    if (!cookie) {
      this.showBanner();
    } else {
      // Check options on load if preferences are known
      const checked = cookie.split(' ');
      this.optionTargets.forEach(option => {
        if (option.name !== 'necessary') {
          if (option.value !== '') {
            option.checked = checked.includes(option.value);
          } else {
            option.checked = !checked.includes(option.name);
          }
        }
      });
    }
  }

  /**
   * Show the banner.
   */
  showBanner() {
    let form = this.element;
    if (form.tagName.toLowerCase() !== 'form') {
      form = document.querySelector('.cookie-notice');
    }
    form.classList.remove('is-hidden');
  }

  /**
   * Show the settings.
   */
  showSettings() {
    this.cookieSettingsTargets.forEach(element => {
      element.classList.toggle('show-settings');
    });
  }

  /**
   * Initialize the embeds.
   *
   * @param  {String} cookie
   */
  initEmbeds(cookie) {
    this.embedTargets.forEach(async embed => {
      if (cookie) {
        const checked = cookie.split(' ');
        if (checked.includes('marketing')) {
          embed.src = embed.dataset.videoSrc;
          return;
        }
      }

      const response = await fetch('cookie-placeholder');
      const placeholder = await response.text();
      embed.outerHTML = placeholder;
    });
  }

  /**
   * Submit the form
   *
   * @param {Event} event
   */
  submitForm(event) {
    event.preventDefault();

    const values = Array.from(this.optionTargets)
      .filter(input => input.value !== '' && input.checked)
      .map(input => input.value);

    Cookie.set(this.storageKey, values.join(' '));
    this.element.classList.add('is-hidden');

    window.location.reload();
  }

  /**
   * Allow all cookies.
   */
  setAll() {
    Cookie.set(this.storageKey, 'necessary functional performance marketing');
    this.element.classList.add('is-hidden');
    window.location.reload();
  }
}
